import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import { Title } from "../../components/Globals";
import { Link } from 'gatsby';
import TeamImage from '../../images/webinars/marketing-automation-roi-calculate.png';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';
import { BsFillPlusSquareFill,  } from "react-icons/bs";
import { AiFillMinusSquare } from "react-icons/ai";

const SalesforceTrainingPage = () => {

    const [course1, setCourse1] = React.useState(false);
    const [course2, setCourse2] = React.useState(false);
    
    const handleSubmit = (event) => {
        event.preventDefault();
        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + btoa('api' + ":" + '5d2220e3b61e542f83de699a4ece81ad-4d640632-9b883e3e'));
        let formData = new FormData();
        formData.append('from', event.target.email.value);
        formData.append('to','sandip@cymetrixsoft.com');
        formData.append('subject', event.target.name.value+' - from Marketing Automation RoI Webinar Page to watch video');
        formData.append('text', 'Name :'+event.target.name.value);
        formData.append('text', 'Company Name :'+event.target.companyname.value);
        formData.append('text','Designation :'+event.target.designation.value);
       
        fetch('https://api.mailgun.net/v3/sandbox0f7d801b76fb45bba72ceff5856c284d.mailgun.org/messages', {
          method: 'post',
          headers: headers,
          body: formData,
        })
        .then(function (data) {
          if(data.status === 200) {
            //alert('Message sent successfuly');
            window.location = 'https://cymetrixsoft-5.hubspotpagebuilder.com/marketing-automation-roi-webinar';
          }
        })
        .catch(function (error) {
          console.log('Request failed', error);
        });
      }
    return (
        <Layout>
     <div className="salesforcetraining-background  flex flex-col">
     <p className="block text-base xl:text-5xl mt-16 xl:px-48 xl:mt-20 md:mt-16 sm:mt-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
        text-white text-uppercase text-center">
         Maximize your Marketing RoI with Data and Marketing Automation!
        </p>
        <p className="block text-base xl:text-xl mt-4 md:mt-6 lg:text-lg md:text-base sm:text-base sma:text-base
        text-white text-uppercase text-center">
Stop wasting your budget on marketing that doesn't work. Find out more about how you can use Data and Marketing automation to drive results and maximize ROI!      </p>
</div>

            <div className="max-w-6xl mx-auto justify-center">
            <Helmet>
        <title>Maximizing Marketing Automation RoI | Free RoI Template | Cymetrix</title>
        <meta name="description" content=" Find out more about how you can use Data and Marketing automation to drive results and maximize ROI! Get free automation RoI calculation template."/>
      <meta name="keywords" content="Marketing Automation Roi, Salesforce marketing automation roi, Cymetrix, cymetrixsoft, Cymetrixsoftware "/>
         <link rel="canonical" href="https://www.cymetrixsoft.com/webinars/marketing-automation-roi-webinar/" />
      </Helmet>
      
               <h1 className="sma:text-xl text-3xl text-center text-extrabold pt-16" style={{color: '#1e407b'}}>WATCH WEBINAR NOW</h1>
                <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap max-w-screen-xl sma:py-1 py-10 md:py-14">
                <div className="sma:px-3 md:w-1/2 lg:w-2/3 px-6 flex md:mb-10">
                        <div className="sma:mt-3 flex flex-col max-w-2xl items-center mt-12">
                            <div className="px-3 py-4 leading-relaxed">
                                <p class="text-justify">While you understand the significance of data and Marketing Automation, you are either unable to get the most out of it or have no idea how you can implement it. Well, you’re not alone! 85% of B2B marketers and 60% of companies out there feel the same way as you! </p>
                                <p class="text-justify">Check out our recorded webinar, "Maximize your Marketing ROI with Data and Marketing Automation!" Simply fill out the form to gain access and start unlocking the full potential of your marketing efforts.
                                </p>
                           
                            </div>

                            <div className="bg-gray-300 w-full px-5 py-8">
                                <h1 className="text-2xl font-extrabold text-left underline">ABOUT THE WEBINAR</h1>
                                <h1 className="text-lg font-bold mt-5 ml-3">What To Expect?</h1>

                                <ul className="list-disc text-base list-inside leading-8 px-3">
                                    <li>Importance of Marketing Automation, to make digital marketing more effective.</li>
                                    <li>Significance of Data Collection in Marketing </li>
                                    <li>Role of technology in data collection</li>
                                    <li>Marketing Strategy Decision based on Analytics for ROI on digital marketing </li>
                                    <li>How to use Marketing automation more effectively</li>
                                    <li>Access to complementary marketing automation RoI template</li>
                                </ul>
                                <h1 className="text-lg font-bold mt-5 ml-3">Fearured Speaker</h1>
                                <p className="text-base font-medium ml-3 text-justify">Industry Veteran Prakash Kolhe, Co-Founder and CEO of Cymetrix Software</p>
                                <br/>
                                 <img src={TeamImage} alt="roi of marketing automation" title="Marketing Automation RoI Webinar" />

                            </div>
                            <hr className="w-full" />

                            </div>
                        </div>
                   

                        <div className="sma:px-3 md:w-1/2 lg:w-1/3 px-6 flex">
                            <div className="sma:mt-3 flex flex-col mx-auto max-w-md items-center mt-12 ">
                                <div className="py-4 border  border-gray-300 shadow-xl">
                                <form className="justify-center flex" onSubmit={handleSubmit} >
                                        <div className="bg-white rounded lg:px-8 px-5 pt-6 pb-8 mb-4 flex flex-col max-w-6xl sm:w-10/12">
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="name">
                                                    Name*
                                                </label>
                                                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                                                        id="name" type="text" placeholder="" name="name" />
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="email">
                                                    Email*
                                                </label>
                                                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                                                        id="email" type="text" placeholder="" name="email" />
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="name">
                                                    Company Name*
                                                </label>
                                                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                                                        id="companyname" type="text" placeholder="" name="companyname" />
                                                </div>
                                            </div>
                                           
                                            
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full">
                                                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="name">
                                                    Designation*
                                                </label>
                                                <textarea className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                                                            id="designation" type="text" placeholder="" name="designation" >
                                                </textarea>
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex mt-2">
                                                <div className="sma:w-full">
                                                <button
                  className="sma:w-full bg-header text-white font-bold py-2 px-4 border-b-4 hover:border-b-#263f5f2 border-gray-500 hover:border-gray-100 uppercase rounded-full" type="submit">
                  Watch Now
                </button>
                                          </div>      </div>
                                            </div>
                                     
                                        </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Layout>
        );
}

export default SalesforceTrainingPage;
